.item-hot-line {
  padding-left: 10px;

  a {
    color: $color-brand2 !important;
    font-size: 22px;
    text-align: center;
    padding: 5px 0 !important;

    &:hover {
      border-bottom: none !important;
    }

  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
    display: none !important;

    a {
      padding: 0;
    }
  }
}



@media screen and (max-height: 768px) {

  li:focus {

    .navbar-collapse.collapse.in {
      display: none !important;
    }
  }

  .logo {
    left: 15px;
  }

}
