
.hot-line-responsive {
  position: fixed;
  margin: 0;
  background: $color-primary;
  text-align: center;
  z-index: 99999;
  border-radius: 5px 5px 0 0;
  width: 180px;
  height: 30px;
  left: 10px;

  .icon-tell {
    background: $color-base1;
    width: 36px;
    height: 30px;
    border-radius: 5px 0 0 0;

    i {
      color: $color-background;
      font-size: 20px !important;
      padding-top: 5px;
    }

  }

  .text-tell {
    width: 115px;
    height: 30px;
    color: $color-background;
    font-size: 20px !important;
    position: absolute;
    right: 5px;
    top: 3px;
    font-size: 14px;
    font-weight: 600;

  }

  b {
    color: $color-background;
  }

  @media screen and (min-width: 992px) {
    display: none;

  }

  @media screen and (max-width: 991px) {
    display: block !important;
    left: 15px;
    bottom: 0;
  }

  @media screen and (max-width: 650px){
    left: 15px;
    bottom: 10px;
    width: 50px ;
    height: 50px;
    background: $color-base1;
    border-radius: 50%;
    box-shadow: 1px 1px 3px $color-primary;

    .icon-tell {
      background: none;
      width: 50px;
      height: 50px;

      i {
        color: $color-background;
        font-size: 30px !important;
        padding-top: 10px;
      }

    }
    .text-tell {
      display: none;
    }

  }
}

.zopim {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  padding: 0;
  border: 0;
  position: fixed;
  z-index: 16000002;
  right: 15px;
  bottom: 0 !important;
  border-radius: 5px 5px 0 0 !important;

  @media screen and (max-width: 650px) {
    bottom: 10px !important;
    border-radius: 5px !important;
    box-shadow: 1px 1px 3px $color-primary;
  }
}
