.note__spricing {
  a, p, span, i, li {
    color: $color-brand3;
  }

  strong {
    text-decoration: underline;
  }

  b {
    color: $color-background;
    font-weight: 400;
  }
}