$url-image-base: '/wp-content/themes/starter/assets/img/';
$font-base: 'Roboto', sans-serif;

$color-primary: #196BAC;
$color-base: #273342;
$color-base1: #005F9E;
$color-background: #ffffff;
$color-brand: #3d3d3d;
$color-brand1: gray ;
$color-brand2: #F6871E;
$color-brand3: #F2FF00;
$color-sucess: #7ED258 ;
$color-err: red ;


.img-center{

  display: block;
  margin: 0 auto;
}
