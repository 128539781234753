.title-ser {
  margin: 0;

  a {
    padding: 10px;
    color: white;
    display: block;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background: $color-base;
    border: none;
    margin: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    outline: none;

    &:hover {
      text-decoration: none;
      background-color: $color-primary;
    }

    &:focus {
      text-decoration: none;
      background: $color-primary !important;
    }

  }
}

.heading {

  font-size: 18px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 0;
  font-weight: 700;

  p {
    text-transform: none;
  }

  .title-more {
    font-size: 50px;
    font-weight: 700;
    color: $color-base;
    display: block;
    line-height: 60px;
    text-transform: uppercase;
    padding: 22px;
    border-left: 8px solid $color-primary;
  }

  h2 {
    font-size: 50px;
    color: white;
    display: block;
    line-height: 60px;
    text-transform: uppercase;
    padding: 22px;
    border-left: 8px solid $color-primary;

    &.tnuv-bg {
      color: $color-base;
    }

    &.case_title {
      color: $color-base;
    }
  }

  span {
    font-size: 33px;
    line-height: 27px;
  }
}

.title-item-tnuv {
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  margin: 0px 0 5px;
  text-transform: uppercase;
}

.title-csg-item {
  font-size: 30px;
  font-weight: 600;
}

.title-post {
  font-size: 18px;
  line-height: 25px;
  padding: 0;
  margin: 0 0 10px 0;
  height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: black;
    text-transform: uppercase;
    line-height: 25px;
    display: block;
    height: 77px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: $color-primary;
    }

    &:focus {
      background: none !important;
      color: $color-primary !important;
    }
  }
}

.membership_listing {

  h1 {
    font-size: 20px;
    line-height: normal;
    font-weight: 700;
    margin: 0px 0 15px;
  }

  h2 {
    font-size: 20px;
    line-height: normal;
    font-weight: 700;
    margin: 0px 0 15px;


  }

  a {
    text-decoration: none !important;
    color: $color-primary;

    &:hover {
      color: $color-base;
    }

    &:focus {
      background: none !important;
      color: $color-base !important;
    }
  }
}

.post_meta {

  h2 {

    p {
      text-transform: none;
    }
  }
}

.row-studecase {
  margin-top: 30px;
}

.footerLinks {
    a {

      color: #dcdce5;
      font-size: 14px !important;
      line-height: 24px;
      margin: 0;

      -webkit-transition: color 1s ease-in-out;
      -moz-transition: color 1s ease-in-out;
      -ms-transition: color 1s ease-in-out;
      -o-transition: color 1s ease-in-out;
      transition: color 1s ease-in-out;

      &:hover {
        color: black;
      }

      &:focus {
        background: none !important;
      }

    }

}

.heading-footer {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  line-height: 50px;
  color: white;
}

.row-pricing-title {
  margin: 30px 0 15px 0;
}

.form-container {

  p {
    line-height: 40px;
  }
}

.watch_text {

  p {
    line-height: 35px;
  }
}