
.blog_large {
  .post_content {
    margin: 0 !important;
  }
}

.title-new-page {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 50px;
  line-height: normal;
  margin-top: 0 !important;
  color: $color-background;
  text-transform: uppercase;
}

.post_meta {
  h2 {

    a {
      font-size: 20px;
      font-weight: 700;
      font-family: Roboto, sans-serif;
      color: $color-primary;
      text-decoration: none;
      outline: 0;
    }

    &:focus {
      background-color: red;
    }

    .post_title {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.blog_large {
  padding: 10px;
  box-shadow: 1px 1px 4px $color-brand1;
  box-sizing: border-box;
}

.sw_search {
  padding: 10px;
  box-shadow: 1px 1px 4px $color-brand1;
  box-sizing: border-box;
}

.sw_archives {
  padding: 10px;
  box-shadow: 1px 1px 4px $color-brand1;
  box-sizing: border-box;
}

.sw_title {

  p {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: $color-primary;;
  }
}

.title-csg-item {

  span {

    strong {
      line-height: 35px;
    }
  }
}