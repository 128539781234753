.client {

  .client__img {
    > img {
      margin: 0 auto;
    }
  }
}

.slider {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 98%;
  margin: 10px auto 20px auto;

  img {
    max-width: 90%;
  }

  .slider__next {
    right: 0;
  }

  .slider__prev {
    left: 0;
  }

  .slider__next,
  .slider__prev {
    font-size: 30px;

    @media screen and(max-width: 767px) {
      font-size: 15px;

    }

    & > i {
      color: $color-primary;
    }

    &::before {
      content: '';
    }
  }

  .slider__dot {
    bottom: -60px;

    @media screen and(max-width: 767px) {
      width: 95%;
      margin: 0 auto;
    }

    li {

      > button {
        background-color: $color-brand;
        border-radius: 50%;
        width: 8px;
        height: 8px;

        &:hover {
          background-color: $color-primary;
          opacity: 0.8;
        }

        &::before {
          font-size: 30px;
          content: '';
        }
      }

      &.slick-active {

        > button {
          background-color: $color-primary;

          &::before {
            color: $color-primary;
          }
        }
      }
    }
  }
}
