.fb_iframe_widget {
  width: 100% !important;

  span {
    width: 100% !important;
    min-height: 25px !important;
  }

  iframe {
    position: relative !important;
    width: 100% !important;
  }
}