body {
  font-family: $font-base;
}

.heading h1, .custom-nav-tabs, .play-btn:hover, ul.pricing-boxes li.best-plan .plan-name:after, .doctorSec a.ico:hover, .service_2_item:hover i, .swipe-navi .swipe-left:hover i:hover, .swipe-navi .swipe-right:hover i:hover, #filter li.selected a, #filter li a:hover, #site-searchform #s:focus, .portfolioFilter ul li a.current {
  border-color: $color-primary;
}

p {
  padding-bottom: 10px;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
}

.global-message.success_message {
  color: $color-sucess;
  padding-bottom: 15px;
}

.error-message {
  color: $color-err;
  font-weight: 500;
  font-size: 15px;
}

.success-message {
  color: $color-sucess;
  font-weight: 500;
  font-size: 15px;
}

